@charset "utf-8";
@use "_mixins/break-points" as *;
@use "base" as *;
/*====================================================================

index.css

=====================================================================*/

/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	@include media-breakpoint-up(md) {
		background-image: url(../img/top/main_bg_tb.jpg);
		background-size: cover;
		height: 71.4vw;
	}
	@include media-breakpoint-up(lg) {
		background-image: url(../img/top/main_bg_pc.jpg);
		height: 54vw;
	}
	@include media-breakpoint-up(xl) {
		height: 850px;
	}
	@include media-breakpoint-up(full) {
		height: 44.27vw;
	}
	@include media-breakpoint-down(sm) {
		background-image: url(../img/top/main_bg_sp.jpg);
		background-size: cover;
		height: 125vw;
	}
	& .l-cont {
		margin-left: 0;
		margin-right: 0;
	}
	&__catch01 {
		position: absolute;
		width: clamp(160px, 72vw, 1100px);
		top: 14.5%;
		left: 12.5%;
		@include media-breakpoint-up(md) {
			top: 20%;
			left: 15%;
			width: clamp(480px, 70vw, 1100px);
		}
		@include media-breakpoint-up(lg) {
			top: 20%;
			left: 19.35%;
			width: clamp(640px, 57.29vw, 1100px);
		}
		@include media-breakpoint-up(xl) {
			top: 13.5%;
			left: 19.35%;
			width: min(57.29vw, 1100px);
		}
	}
	&__catch02 {
		position: absolute;
		top: 56%;
		left: 0;
		right: 0;
		margin: auto;
		width: clamp(140px, 66vw, 704px);
		@include media-breakpoint-up(md) {
			top: 62%;
			width: clamp(400px, 36.66vw, 704px);
		}
		@include media-breakpoint-up(lg) {
			top: 58%;
			width: clamp(600px, 36.66vw, 704px);
		}
		@include media-breakpoint-up(xl) {
			top: 62%;
		}
	}
	&__books {
		position: absolute;
		right: 2%;
		width: 36%;
		z-index: z-index(module, part02);
		bottom: -1em;
		@include media-breakpoint-up(md) {
			width: 20%;
			bottom: 4em;
		}
		@include media-breakpoint-up(lg) {
			width: 22%;
		}
		@include media-breakpoint-up(xl) {
			width: min(19.48%, 374px);
		}
		@include media-breakpoint-up(xxl) {
			bottom: -2em;
		}
	}
	&__left {
		position: absolute;
		width: 44%;
		left: 3%;
		bottom: -0.5em;
		z-index: z-index(module, part03);
		@include media-breakpoint-up(md) {
			width: 18.23%;
			top: 11%;
			left: 1%;
			bottom: auto;
			width: 20%;
		}
		@include media-breakpoint-up(lg) {
			top: 13%;
		}
		@include media-breakpoint-up(xl) {
			width: 18.23%;
			top: 11%;
			left: 3%;
		}
	}
	&__right {
		position: absolute;
		width: 22.5%;
		top: 40%;
		right: -2%;
		@include media-breakpoint-up(md) {
			width: 20%;
			top: 28%;
			right: -5%;
		}
		@include media-breakpoint-up(lg) {
			width: 19%;
			top: 17%;
			right: 1%;
		}
		@include media-breakpoint-up(xl) {
			width: 17.5%;
			right: 3.5%;
		}
	}
	&__num {
		position: absolute;
		top: 40%;
		left: -1%;
		@include media-breakpoint-up(md) {
			top: 50%;
			left: 1%;
		}
		@include media-breakpoint-up(lg) {
			top: 48%;
			left: 2%;
		}
		@include media-breakpoint-up(xl) {
			top: 50%;
			left: auto;
			right: 70%;
		}
		&__inner {
			display: flex;
			justify-content: center;
			align-items: center;
			background: url(../img/common/frame_bg01.svg) center center/contain no-repeat;
			width: 26vw;
			height: 24vw;
			@include media-breakpoint-up(md) {
				width: 20vw;
				height: 18.6vw;
			}
			@include media-breakpoint-up(lg) {
				width: 21vw;
				height: 20.3vw;
			}
			@include media-breakpoint-up(xl) {
				width: 300px;
				height: 290px;
			}
			& > div {
				@include line-h(1.2);
				text-align: center;
			}
		}
		p {
			@include f-w(800);
			@include l-sp(0.02em);
			@include f-s_xxs(0.45, 18);
			@include media-breakpoint-up(md) {
				@include f-s_md(0.8, 6);
			}
			@include media-breakpoint-up(lg) {
				@include f-s_lg(1.14, 6);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(24);
			}
			&.is-number {
				b {
					@include f-family(font03);
					font-size: 3em;
					color: $point_c;
					@include f-w(400);
				}
				small {
					font-size: 1.5em;
					padding-left: 0.1em;
				}
			}
		}
	}
}

/* common
----------------------------------------------------------------*/

/*--------------------------------------------------------------------/
	contents
/--------------------------------------------------------------------*/

.main {
	overflow: hidden;
}

.contents {
	@include bg-gray;
	position: relative;
}

/* top-news */
.top-news {
	position: relative;
	top: 2em;
	//&::before {
	//	position: absolute;
	//	top: 0;
	//	left: 0;
	//	content: "";
	//	width: 100%;
	//	height: 2em;
	//	background: $sub_c;
	//}
	z-index: z-index(module, part04);
	@include media-breakpoint-up(md) {
		top: -2em;
	}
	&__inner {
		position: relative;
		max-width: 1000px;
		@include radius_m;
		@include m-a;
		padding: clamp(1.5em, 2vw, 2em) clamp(1.5em, 5%, 3em);
		box-shadow: 0 0 10px rgba($black, 0.05);
		background: $white;
		z-index: z-index(module, part02);
		@include media-breakpoint-up(lg) {
			padding: clamp(2em, 3vw, 3em) clamp(1.5em, 5%, 3em);
		}
	}
	&__grid {
		display: grid;
		@include media-breakpoint-up(md) {
			grid-template-columns: 180px 1fr;
			column-gap: clamp(2em, 4vw, 4em);
		}
	}
	&__tit {
		@include line-h(1);
		@include media-breakpoint-up(md) {
			margin-bottom: clamp(2em, 3vw, 3em);
			grid-column: 1 / 2;
			grid-row: 1 / 2;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: 1em;
		}
		b {
			@include f-family(font02);
			display: block;
			color: $main_c;
			@include f-w(500);
			@include f-s_xxs(2.5, 24);
			margin-bottom: 0.2em;
			@include media-breakpoint-up(md) {
				@include f-size(50);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(54);
			}
		}
		small {
			display: block;
			@include f-w(500);
			@include f-s_xxs(1.25, 12);
			@include media-breakpoint-up(md) {
				@include f-size(24);
			}
		}
	}
	&__list {
		@include media-breakpoint-up(md) {
			grid-column: 2 / 3;
			grid-row: 1 / 3;
		}
		&__inner {
			max-height: 200px;
			overflow-y: auto;
			@include scroll-bar;
			& > li {
				position: relative;
				border-bottom: 1px solid $l-gray;
				&:first-child {
					border-top: 1px solid $l-gray;
				}
				&::after {
					position: absolute;
					bottom: -1px;
					left: 0;
					content: "";
					width: 0;
					height: 1px;
					background: $hover_c;
					@include transition;
					z-index: z-index(module, part02);
				}
				&:hover {
					&::after {
						width: 100%;
					}
				}
			}
			a {
				@include dec-none;
				color: $txt_c;
				display: grid;
				padding: 1em 0;

				@include line-h(1.4);
				@include media-breakpoint-up(sm) {
					column-gap: 1em;
					grid-template-columns: 86px auto 1fr;
				}
				@include media-breakpoint-down(xs) {
					grid-template-columns: 80px 1fr;
					grid-template-rows: auto 1fr;
					column-gap: 0.5em;
					row-gap: 0.3em;
				}
			}
		}
		&__date {
			@include f-w(700);
			@include l-sp(0);
			font-size: 1.14em;
			grid-column: 1 / 2;
		}
		&__category {
			grid-column: 2 / 3;
			@include flex-wrap;
			gap: 0.2em;
			li {
				span {
					font-size: 0.9em;
				}
			}
		}
		&__tit {
			@include media-breakpoint-up(sm) {
				grid-column: 3 / 4;
				font-size: 1.14em;
			}
			@include media-breakpoint-down(xs) {
				grid-column: 1 / 3;
				grid-row: 2 / 3;
			}
		}
	}
	&__btn {
		@include media-breakpoint-up(md) {
			grid-column: 1 / 2;
			grid-row: 2 / 3;
		}
		@include media-breakpoint-down(sm) {
			@include m-a;
			width: clamp(240px, 100%, 360px);
			margin-top: clamp(2em, 3vw, 3em);
		}
		a {
			font-size: 1em;
			padding: 0.75em 3%;
		}
	}
}

/* top-about */
.top-about {
	padding-top: clamp(3em, 6vw, 6em);
	padding-bottom: clamp(2em, 4vw, 4em);
	&__inner {
		@include media-breakpoint-up(md) {
			@include flex-end;
			position: relative;
		}

		@include media-breakpoint-up(xl) {
			min-height: 640px;
		}
		@include media-breakpoint-down(md) {
			@include flex-c-reverse;
			padding-left: map-get($contmargin, sp);
			padding-right: map-get($contmargin, sp);
		}
	}
}

.top-about {
	&__txt {
		position: relative;
		z-index: z-index(module, part02);
		background: $white;
		@include media-breakpoint-up(lg) {
			width: 60%;
			padding: 0 clamp(3em, 6vw, 6em) clamp(3em, 6vw, 6em) clamp(6em, 16%, 16em);
			border-top-left-radius: clamp(8px, 2.5vw, 40px);
			border-bottom-left-radius: clamp(8px, 2.5vw, 40px);
		}
		@include media-breakpoint-up(xl) {
			padding-left: clamp(6em, 18%, 18em);
		}
		@include media-breakpoint-down(md) {
			margin-top: clamp(-4.5em, 6.5vw, -6.5em);
			width: 100%;
			padding: 0 clamp(1.5em, 5%, 4.5em) clamp(2em, 3vw, 3em);
			@include radius_l;
		}
		&__inner {
			position: relative;
			@include media-breakpoint-up(lg) {
				padding-top: clamp(3em, 5vw, 5em);
				max-width: 620px;
			}
			@include media-breakpoint-down(md) {
				padding-top: clamp(6em, 8vw, 8em);
			}
		}
		&__tit {
			color: $sub_c;
			@include f-w(700);
			@include line-h(1);
			@include f-s_xxs(1.25, 40);
			@include media-breakpoint-up(lg) {
				position: absolute;
				top: -0.5em;
				@include f-s_lg(2, 24);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(60);
			}
			@include media-breakpoint-down(md) {
				margin-bottom: 0.75em;
			}
		}
		&__catch {
			@include f-s_xxs(1.14, 12);
			@include f-w(700);
			padding-bottom: clamp(0.5em, 0.75vw, 0.75em);
			margin-bottom: clamp(0.75em, 1vw, 1em);
			border-bottom: 0.3em dotted $sub_c;
			@include media-breakpoint-up(lg) {
				@include f-s_lg(1.14, 12);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(32);
			}
		}
		&__txt {
			@include media-breakpoint-up(md) {
				@include line-h(2);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(18);
			}
		}
		&__btn {
			width: clamp(240px, 100%, 360px);
			margin-top: clamp(2em, 3vw, 3em);
			@include media-breakpoint-up(lg) {
				margin-top: clamp(3em, 4vw, 4em);
			}
			@include media-breakpoint-down(sm) {
				@include m-a;
			}
			a {
				font-size: 1em;
				padding: 0.75em 3%;
			}
		}
	}
}

.top-about {
	&__video {
		position: relative;
		z-index: z-index(module, part02);
		width: 90%;
		@include media-breakpoint-up(lg) {
			position: absolute;
			top: 4em;
			right: 50%;
			width: 45%;
		}
		@include media-breakpoint-up(xl) {
			width: clamp(500px, 49%, 800px);
		}
		@include media-breakpoint-down(md) {
			max-width: 640px;
			@include m-a;
		}
		&__inner {
			padding: clamp(1.5em, 3vw, 3em) clamp(2em, 8%, 4em);
			background: $bg_c1;
			@include radius_m;
			box-shadow: 0 0 10px rgba($black, 0.1);
		}
		&__video {
			width: 100%;
			@include m-a;
			iframe {
				width: 100%;
				height: 100%;
				aspect-ratio: 16 / 9;
			}
		}
		&__tit {
			margin-top: 1em;
			span {
				position: relative;
				color: $txt_c;
				@include line-h(1.4);
				@include f-w(700);
				padding: 1em 1.5em 1em 0;
				background: $other_c1;
				display: inline-block;
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
				@include media-breakpoint-up(sm) {
					padding: 1.25em 1.5em 1.25em 0;
				}
				&::before {
					position: absolute;
					top: 0;
					right: calc(100% - 1px);
					content: "";
					width: clamp(2.5em, 12vw, 4.5rem);
					height: 100%;
					background: $other_c1;
					border-top-left-radius: 5px;
					border-bottom-left-radius: 5px;
					@include media-breakpoint-up(lg) {
						width: 5em;
					}
				}
			}
		}
	}
}

/* top-sketch */
.top-sketch {
	position: relative;
	&::before {
		position: absolute;
		left: 0;
		bottom: 0;
		content: "";
		width: 100%;
		height: 70%;
		background: $other_c8;
	}
	&::after {
		position: absolute;
		left: 0;
		bottom: calc(70% - 1px);
		content: "";
		width: 100vw;
		height: 13.125vw;
		background: url(../img/common/common_wave_green.svg) left bottom/cover no-repeat;
	}
	&__inner {
		position: relative;
		padding-top: clamp(9em, 18vw, 18em);
		padding-bottom: clamp(6em, 9vw, 9em);
		z-index: z-index(module, part01);
		&::before {
			@include media-breakpoint-up(lg) {
				position: absolute;
				left: -2%;
				bottom: 2em;
				content: "";
				width: clamp(96px, 23%, 430px);
				height: clamp(200px, 47vw, 892px);
				background: url(../img/top/top-sketch_bg01.png) top center/contain no-repeat;
			}
		}
		&::after {
			@include media-breakpoint-up(lg) {
				position: absolute;
				right: 3%;
				bottom: 2em;
				content: "";
				width: clamp(114px, 19.8%, 380px);
				height: clamp(150px, 26vw, 500px);
				background: url(../img/top/top-sketch_bg02.png) top center/contain no-repeat;
			}
		}
	}
}

.top-sketch {
	&__txt {
		position: relative;
		max-width: 1100px;
		@include radius_s;
		padding-top: clamp(1em, 2vw, 2em);
		background: $white;
		z-index: z-index(module, part02);
		@include m-a;
		&__add {
			position: relative;
			top: -1em;
			width: 100%;
		}
		&__inner {
			@include m-a;
			padding: clamp(2em, 3vw, 3em) clamp(1.5em, 5%, 4.5em) clamp(3em, 6vw, 6em);
		}
		&__img {
			position: absolute;
			bottom: calc(100% - 3em);
			left: -4%;
			width: clamp(160px, 36%, 320px);
			z-index: z-index(module, part03);
			@include media-breakpoint-up(lg) {
				bottom: calc(100% - 2em);
				width: clamp(200px, 48%, 456px);
			}
		}
		&__pic {
			position: absolute;
			@include media-breakpoint-up(lg) {
				width: clamp(120px, 32.72%, 360px);
				bottom: -5em;
				right: -12%;
			}
			@include media-breakpoint-up(xl) {
				bottom: auto;
				top: 20%;
				right: -26%;
			}
			@include media-breakpoint-down(md) {
				display: none;
			}
		}
		&__tit {
			position: relative;
			max-width: 876px;
			@include m-a;
			margin-bottom: clamp(1.5em, 3vw, 3em);
			&__add {
				position: absolute;
				width: clamp(120px, 50%, 440px);
				bottom: calc(100% + 1em);
				left: 60%;
			}
		}
		&__catch {
			text-align: center;
			@include f-s_xxs(1.14, 12);
			@include line-h(1.4);
			margin-bottom: clamp(1.25em, 1.75vw, 2em);
			text-align: center;
			@include radius;
			@include media-breakpoint-up(xl) {
				@include f-size(30);
			}
			span {
				display: inline-block;
				padding: 0.5em 1em;
				color: $white;
				background: $sub_c;
			}
		}
		&__txt {
			@include line-h(2);
			@include f-s_xxs(0.9, 3);
			@include media-breakpoint-up(md) {
				text-align: center;
			}
			@include media-breakpoint-up(xl) {
				@include f-size(19);
			}
		}
		&__btn {
			min-width: 240px;
			margin-top: clamp(3em, 4vw, 4em);
			max-width: 360px;
			@include m-a;
		}
	}
}

/* top-anxiety */
.top-anxiety {
	position: relative;
	background: $bg_c1;
	padding-bottom: clamp(5em, 8vw, 8em);
	&__wrap {
		position: relative;
		padding-top: clamp(2em, 4vw, 4em);
		padding-bottom: clamp(5em, 8vw, 8em);
		background: $white;
		border-bottom-left-radius: clamp(8px, 16vw, 200px);
		border-bottom-right-radius: clamp(8px, 16vw, 200px);
		&::after {
			position: absolute;
			top: calc(100% - 1px);
			left: calc(50% - 2em);
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 3em 2em 0 2em;
			border-color: $white transparent transparent transparent;
			@include media-breakpoint-up(lg) {
				left: calc(50% - 4em);
				border-width: 5em 4em 0 4em;
			}
		}
	}
	&__inner {
	}
	&__tit {
		display: flex;
		margin-bottom: clamp(2em, 4vw, 4em);
		@include media-breakpoint-up(md) {
			column-gap: 6%;
			align-items: baseline;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: clamp(0.5em, 1vw, 1em);
			max-width: 480px;
			@include m-a;
		}
		&__add {
			@include media-breakpoint-up(md) {
				width: 42.5%;
			}
			@include media-breakpoint-down(sm) {
				width: 70%;
				@include m-a;
			}
		}
		&__txt {
			@include media-breakpoint-up(md) {
				width: 51%;
			}
		}
	}
	&__list {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
		column-gap: 2%;
		row-gap: 1em;
		@include media-breakpoint-up(md) {
			grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
			column-gap: 2%;
			row-gap: 1em;
		}
		@include media-breakpoint-up(lg) {
			grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
			gap: 1.5em;
		}
	}
}

.top-anxiety__box {
	border: 1px solid $d-gray;
	@include radius_m;
	&:nth-of-type(4n + 1) {
		background: $bg_c7;
	}
	&:nth-of-type(4n + 2) {
		background: $bg_c8;
	}
	&:nth-of-type(4n + 3) {
		background: $bg_c9;
	}
	&:nth-of-type(4n + 4) {
		background: $other_c2;
	}
	&__inner {
		padding: clamp(1em, 1.5vw, 1.5em) clamp(1.5em, 2.5vw, 2.5em);
		@include flex-r-reverse;
		align-items: center;
		column-gap: 1.5em;
	}
	&__txt {
		width: 1fr;
		flex-grow: 1;
		flex-shrink: 1;
		@include f-w(700);
		@include line-h(1.5);
		@include media-breakpoint-up(xl) {
			font-size: 1.14em;
		}
	}
	&__img {
		width: clamp(40px, 14%, 68px);
		flex-shrink: 0;
		&__inner {
			@include aspect-img(17, 27);
		}
	}
}

/* .top-service */
.top-service {
	background: $bg_c1;
	&__head {
		&__tit {
			@include m-a;
			width: clamp(200px, 100%, 1100px);
		}
	}
	&__txt {
		position: relative;
		background: rgba($sub_c, 0.7);
		margin-top: clamp(3em, 6vw, 6em);
		padding-bottom: clamp(3em, 8vw, 8em);
		&::before {
			position: absolute;
			left: 0;
			bottom: 0;
			content: "";
			width: 100%;
			height: 75%;
			background: $sub_c;
			@include media-breakpoint-up(xl) {
				height: calc(100% - 20em);
			}
		}
		&__inner {
			position: relative;
			padding-top: clamp(4.75em, 7vw, 8em);
			z-index: z-index(module, part02);
		}
		&__tit {
			position: absolute;
			top: -1.5em;
			left: 0;
			right: 0;
			text-align: center;
			@include f-s_xxs(0.94, 12);
			@include media-breakpoint-up(xl) {
				@include f-size(32);
			}
			span {
				display: inline-block;
				padding: 0.75em 1em;
				@include f-w(700);
				background: $white;
				@include radius;
				@include media-breakpoint-up(md) {
					padding: 0.75em 1.75em;
				}
			}
		}
		&__list {
			@include m-a;
			max-width: 1240px;
			display: grid;
			row-gap: 2.5em;
			counter-reset: plan-num;
			@include media-breakpoint-up(md) {
				grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
				column-gap: 2%;
			}
			@include media-breakpoint-up(lg) {
				grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
				column-gap: 2.8%;
			}
		}
		&__txt {
			margin-top: clamp(2em, 3vw, 3em);
			color: $white;
			@include f-s_xxs(0.94, 8);
			@include media-breakpoint-up(lg) {
				text-align: center;
			}
			@include media-breakpoint-up(xl) {
				@include f-size(24);
			}
		}
		&__btn {
			min-width: 240px;
			margin-top: clamp(3em, 4vw, 4em);
			max-width: 360px;
			@include m-a;
			a {
				font-size: 1em;
				padding: 0.75em 3%;
			}
		}
	}
}

.top-service__box {
	position: relative;
	padding: clamp(1.5em, 3vw, 3em) clamp(1.5em, 5%, 2em) clamp(1.5em, 3vw, 3em);
	content: "";
	background: $white;
	@include radius_l(8px, 6vw, 96px);
	@include media-breakpoint-up(md) {
		padding: clamp(1.5em, 3vw, 3em) clamp(1em, 5%, 2em) clamp(1.25em, 3vw, 3em);
	}
	@include media-breakpoint-up(lg) {
		padding: clamp(1.5em, 3vw, 3em) clamp(2em, 8%, 4.5em) clamp(1.5em, 3vw, 3em);
	}
	&::before {
		position: absolute;
		bottom: calc(100% - 0.5em);
		left: 50%;
		transform: translateX(-50%);
		color: $other_c4;
		@include f-family(font02);
		@include l-sp(0);
		@include line-h(1);
		counter-increment: plan-num;
		content: counter(plan-num, decimal-leading-zero);
		@include f-w(500);
		@include f-s_xxs(3, 48);
		@include media-breakpoint-up(md) {
			@include f-s_md(4, 16);
		}
		@include media-breakpoint-up(lg) {
			font-size: 5em;
		}
	}
	&__inner {
		@include flex-c-reverse;
		row-gap: 0.75em;
	}
	&__txt {
		text-align: center;
		@include f-w(700);
		@include line-h(1.5);
		@include f-s_xxs(1, 8);
		@include media-breakpoint-up(md) {
			@include f-s_md(0.8, 6);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(22);
		}
	}
	&__img {
		width: 90%;
		@include m-a;
		max-width: 320px;
		&__inner {
			@include aspect-img(57, 37);
		}
	}
}

/* top-reserve */
.top-reserve {
	padding-top: clamp(2em, 4vw, 4em);
	padding-bottom: clamp(3em, 6vw, 6em);
	background: $bg_c1;
	@include media-breakpoint-down(sm) {
		padding-bottom: clamp(7em, 12vw, 12em);
	}
	.l-cont {
		position: relative;
	}
	&__add {
		position: absolute;

		@include media-breakpoint-up(md) {
			top: 0;
			width: 21.8%;
		}
		@include media-breakpoint-down(sm) {
			bottom: clamp(-7em, -12vw, -12em);
			width: 20vw;
		}
		&.is-add01 {
			left: 2%;
		}
		&.is-add02 {
			right: 2%;
		}
	}
	&__inner {
		@include media-breakpoint-up(md) {
			@include m-a;
			width: 56%;
		}
	}
	&__tit {
		width: 94%;
		@include m-a;
		margin-bottom: clamp(0.75em, 1.5vw, 1.5em);
	}
	&__txt {
		margin-bottom: 0.75em;
		text-align: center;
		@include media-breakpoint-up(md) {
			@include f-s_md(1, 4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
	}
	&__btn {
		@include m-a;
		max-width: 360px;
		@include media-breakpoint-up(md) {
			max-width: 700px;
		}
		a {
			@include btn_arrow;
			@include media-breakpoint-up(md) {
				@include f-s_md(1.25, 10);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(32);
			}
		}
	}
}

/* top-number */
.top-number {
	padding-bottom: clamp(3em, 6vw, 6em);
	background: $white;
	&__inner {
		position: relative;
	}
	&__separate {
		display: flex;
		& > * {
			width: 50%;
		}
	}
	&__notes {
		display: flex;
		justify-content: flex-end;
		margin-top: 1em;
		p {
			padding: 0.4em 1em;
			background: $bg_c2;
		}
	}
}

.top-number {
	&__item {
		overflow: hidden;
		position: relative;
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			width: 100%;
			height: 100%;
			z-index: z-index(module, part01);
		}
		&.is-sketch {
			background: url(../img/top/number_bg01.jpg) center center/cover no-repeat;
			&::before {
				background: rgba($other_c7, 0.8);
			}
		}
		&.is-consultation {
			background: url(../img/top/number_bg02.jpg) center center/cover no-repeat;
			&::before {
				background: rgba($other_c3, 0.8);
			}
		}
		&__inner {
			position: relative;
			width: 100%;
			padding-top: 36vw;
			color: $white;
			z-index: z-index(module, part03);
			@include media-breakpoint-up(md) {
				padding-top: 25vw;
			}
			@include media-breakpoint-up(xxl) {
				padding-top: 40%;
			}
			&::before {
				position: absolute;
				top: 2vw;
				left: 2vw;
				right: 2vw;
				bottom: 2vw;
				content: "";
				width: calc(100% - 4vw);
				height: calc(100% - 4vw);
				border: 1px solid $white;
				@include media-breakpoint-up(md) {
					top: 1em;
					left: 1em;
					right: 1em;
					bottom: 1em;
					width: calc(100% - 2em);
					height: calc(100% - 2em);
					border-width: 2px;
				}
			}
		}
		&__txt {
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			&__inner {
				text-align: center;
			}
			&__tit {
				@include fs_xxs(8, 32);
			}
			&__num {
				@include line-h(1);
				b {
					@include f-w(700);
					@include fs_xxs(24, 120);
				}
				small {
					@include f-w(700);
					@include fs_xxs(16, 56);
				}
			}
		}
	}
}

/* top-consultation */
.top-consultation {
	position: relative;
	overflow: hidden;
	padding-top: clamp(2em, 4vw, 4em);
	padding-bottom: clamp(2em, 4vw, 4em);
	background: $white;
	&::before {
		position: absolute;
		top: 0;
		left: 0;
		content: "";
		width: 90%;
		height: 100%;
		border-top-right-radius: clamp(8px, 2.5vw, 40px);
		border-bottom-right-radius: clamp(8px, 2.5vw, 40px);
		background: $bg_c1;
	}
	&__tit {
		position: relative;
		@include f-s_xxs(1.25, 20);
		@include f-w(700);
		padding-left: 1em;
		margin-bottom: clamp(1em, 1.5vw, 2em);
		@include media-breakpoint-up(xl) {
			@include f-size(40);
		}
		&::before {
			position: absolute;
			top: 0.6em;
			left: 0;
			content: "";
			width: 0.8em;
			height: 0.15em;
			@include radius(10px);
			background: $sub_c;
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part03);
	}
	&__list {
		margin-bottom: clamp(2rem, 5vw, 5rem);
	}
	&__btn {
		@include media-breakpoint-up(lg) {
			position: absolute;
			top: -1em;
			right: 0;
			min-width: 240px;
		}
		@include media-breakpoint-down(md) {
			width: 100%;
			max-width: 430px;
			margin-top: clamp(3em, 4vw, 4em);
			@include m-a;
		}
		a {
			font-size: 1em;
			padding: 0.75em 3%;
		}
	}
}

// swiper custom
.top-consultation {
	.swiper {
		width: 100%;
		overflow: visible;
		position: relative;
		//padding-bottom: clamp(5rem, 8vw, 8rem);
		margin-bottom: 5rem;
		@include media-breakpoint-up(xl) {
			margin-bottom: 7rem;
		}
	}
	.swiper-control {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		max-width: map-get($grid-num, xl) px;
		@include media-breakpoint-up(full) {
			max-width: floor-decimal(calc(map-get($grid-num, xl) / map-get($grid-num, full)), 4) * 100vw;
		}
	}
	.swiper-next_prev {
		position: relative;
		display: flex;
		justify-content: space-between;
		width: calc(100% + 32px);
		left: -16px;
		top: 50%;
		z-index: z-index(module, part01);
		transform: translateY(-50%);
		@include media-breakpoint-up(md) {
			width: calc(100% + 64px);
			left: -32px;
		}
		@include media-breakpoint-up(lg) {
			width: calc(100% + 90px);
			left: -45px;
		}
	}
	.swiper-button-prev {
		left: 0;
	}
	.swiper-button-next {
		right: 0;
	}
	.swiper-button-prev,
	.swiper-button-next {
		margin: 0;
		float: none;
		display: block;
		background-image: none;
		width: 48px;
		height: 48px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: z-index(module, part03);
		@include media-breakpoint-up(md) {
			width: 64px;
			height: 64px;
		}
		@include media-breakpoint-up(lg) {
			width: 90px;
			height: 90px;
		}
		&::before {
			content: "";
			position: absolute;
			background: $white;
			border: 1px solid $p-gray;
			box-shadow: 0 0 10px rgba($black, 0.05);
			@include transition;
			width: 100%;
			height: 100%;
			@include radius(50%);
		}
		&::after {
			position: absolute;
			top: 50%;
			left: 50%;
			color: $sub_c;
			font-size: 1.5em;
			@include transition;
			z-index: z-index(module, part03);
			@include media-breakpoint-up(lg) {
				@include f-size(48);
			}
		}
		&:hover {
			&::before {
				background: $hover_c;
				border-color: $hover_c;
			}
			&::after {
				color: $white;
			}
		}
	}
	.swiper-button-prev {
		@include icon(arrow2_left, after);
		&::after {
			transform: translate(-60%, -50%);
		}
	}
	.swiper-button-next {
		@include icon(arrow2_right, after);

		&::after {
			transform: translate(-40%, -50%);
		}
	}
	.swiper-scrollbar {
		position: absolute;
		top: calc(100% + 2rem);
		width: 100%;
		height: 6px;
		z-index: z-index(module, part01);
		background: $p-gray;
		@include media-breakpoint-up(lg) {
			height: 8px;
			top: calc(100% + 3rem);
		}
	}
	.swiper-scrollbar-drag {
		background: $m-gray;
		height: 6px;
		@include transition;
		cursor: pointer;
		@include media-breakpoint-up(md) {
			height: 8px;
		}
		&:hover {
			background-color: $main_c;
		}
	}
}

/* top-books */
.top-books {
	padding-top: clamp(3em, 6vw, 6em);
	padding-bottom: clamp(5em, 6vw, 6em);
	background: $white;
	&__inner {
		@include bg-gray;
		@include radius_l;
		padding: clamp(1.5em, 3vw, 3em) 0 clamp(2em, 4.5vw, 4.5em);
		border: 1px solid $sub_c;
		display: flex;
		@include media-breakpoint-up(md) {
			padding: clamp(2em, 4vw, 4em) 0 clamp(2em, 4.5vw, 4.5em);
			justify-content: space-between;
			align-items: center;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: clamp(1.5em, 2vw, 2em);
		}
	}
	&__left {
		@include media-breakpoint-up(md) {
			width: 48%;
		}
		@include media-breakpoint-down(sm) {
			padding-right: clamp(1.5rem, 5%, 4rem);
		}
	}
	&__tit {
		@include flex-column;
		row-gap: clamp(1em, 2vw, 2em);
		margin-bottom: clamp(1em, 1.5vw, 1.5em);
		&__add {
			display: block;
			@include m-a;
			width: clamp(50px, 20%, 100px);
			@include media-breakpoint-down(sm) {
				padding-left: clamp(1.5rem, 5%, 4rem);
			}
		}
		&__inner {
			color: $white;
			background: $sub_c;
			text-align: center;
			@include f-w(700);
			padding: 0.5em 4%;
			@include f-s_xxs(1.14, 16);
			@include media-breakpoint-up(md) {
				@include f-s_md(1.5, 16);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(40);
			}
			@include media-breakpoint-down(sm) {
				padding-left: clamp(1.5rem, 5%, 4rem);
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			@include line-h(2);
			padding-left: clamp(2.5em, 16%, 6em);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
		@include media-breakpoint-down(sm) {
			padding-left: clamp(1.5rem, 5%, 4rem);
		}
	}
	&__right {
		@include media-breakpoint-up(md) {
			width: 48%;
			padding-right: clamp(1.5em, 5%, 4em);
		}
		@include media-breakpoint-down(sm) {
			padding: 0 clamp(1.5rem, 5%, 4rem);
		}
	}
	&__list {
		display: grid;
		@include m-a;
		grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
		gap: 1.5em 3%;
		@include media-breakpoint-up(md) {
			max-width: 560px;
		}
		@include media-breakpoint-down(sm) {
			@include m-a;
			max-width: 480px;
		}
		li {
			max-width: 260px;
			@include flex-column;
			row-gap: 0.5em;
		}
	}
	&__img {
		width: 100%;
	}
	&__btn {
		width: 100%;
		a {
			display: block;
			text-align: center;
			@include f-s_xxs(0.74, 4);
			@include radius(999px);
			@include dec-none;
			@include line-h(1.2);
			padding: 0.75em 4%;
			color: $white;
			background: $point_c;
			@include transition;
			@include media-breakpoint-up(md) {
				@include f-s_md(0.8, 2);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(16);
			}
			&:hover {
				background: $point_ch;
			}
		}
	}
}

/* top-blog */
.top-blog {
	padding-top: clamp(3em, 6vw, 6em);
	padding-bottom: clamp(5em, 9vw, 9em);
	background: $white;
	&__inner {
		position: relative;
	}
	&__tit {
		margin-bottom: clamp(1.5em, 2.5vw, 2.5em);
		b {
			position: relative;
			@include f-family(font02);
			@include f-w(500);
			padding-left: 0.6em;
			padding-right: 0.2em;
			@include f-s_xxs(2, 50);
			color: $sub_c;
			@include media-breakpoint-up(xl) {
				@include f-size(92);
			}
			&::before {
				position: absolute;
				top: 0;
				left: 0;
				content: "";
				width: 0.5em;
				height: 1em;
				background: url(../img/common/icon_pencil.svg) center center/contain no-repeat;
			}
		}
		small {
			@include f-s_xxs(1, 10);
			@include media-breakpoint-up(xl) {
				@include f-size(28);
			}
		}
	}
	&__list {
		@include flex-column;
		row-gap: clamp(3em, 6vw, 6em);
	}
	&__type {
		&__tit {
			position: relative;
			@include f-s_xxs(1.25, 20);
			@include f-w(700);
			padding-left: 1em;
			margin-bottom: clamp(1em, 1.5vw, 2em);
			@include media-breakpoint-up(xl) {
				@include f-size(40);
			}
			&::before {
				position: absolute;
				top: 0.6em;
				left: 0;
				content: "";
				width: 0.8em;
				height: 0.15em;
				@include radius(10px);
				background: $sub_c;
			}
			&.is-popular {
				margin-bottom: clamp(3em, 3.5vw, 3.5em);
			}
		}
		&__list {
			display: grid;
			row-gap: 3em;
			@include media-breakpoint-up(sm) {
				grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
				column-gap: 4%;
				row-gap: clamp(2em, 3vw, 3em);
			}
			@include media-breakpoint-up(md) {
				grid-template-columns: repeat(auto-fit, minmax(280px, 32%));
				column-gap: 2%;
			}
			@include media-breakpoint-up(lg) {
				grid-template-columns: repeat(auto-fit, minmax(250px, 31.33%));
				column-gap: 3%;
			}
			@include media-breakpoint-up(xl) {
				grid-template-columns: repeat(auto-fit, minmax(250px, 30%));
				column-gap: 5%;
			}
			&.popular-list {
				row-gap: 4.5em;
				@include media-breakpoint-up(sm) {
					row-gap: clamp(3em, 4vw, 4em);
				}
			}
		}
	}
	&__btn {
		@include media-breakpoint-up(lg) {
			position: absolute;
			top: 2em;
			right: 0;
			min-width: 240px;
		}
		@include media-breakpoint-down(md) {
			width: 100%;
			max-width: 430px;
			margin-top: clamp(3em, 4vw, 4em);
			@include m-a;
		}
		a {
			font-size: 1em;
			padding: 0.75em 3%;
		}
	}
}
