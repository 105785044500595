@charset "UTF-8";
/* webfont 変数　*/
/* ofi */
@media (min-width: 1753px) {
  /*====================================================================/
  /*--Large PC 以上--
  /====================================================================*/
}
@media (min-width: 1553px) {
  /*====================================================================/
  /*---PC 以上--
  /====================================================================*/
}
@media (min-width: 1176px) {
  /*====================================================================/
  /*--XGAサイズ　iPadPro 以上--
  /====================================================================*/
}
@media (min-width: 768px) {
  /*====================================================================/
  /*--タブレット　iPadmini 以上--
  /====================================================================*/
}
@media (min-width: 576px) {
  /*====================================================================/
  /*--スマホ 以上--
  /====================================================================*/
}
@media (min-width: 376px) {
  /*====================================================================/
  /*--iPhone 以上--
  /====================================================================*/
}
@media (max-width: 1752px) {
  /*====================================================================/
  /*--Large PC 未満--
  /====================================================================*/
}
@media (max-width: 1552px) {
  /*====================================================================/
  /*--PC 未満--
  /====================================================================*/
}
@media (max-width: 1175px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro 未満--
  /====================================================================*/
}
@media (max-width: 767px) {
  /*====================================================================/
  /*--タブレット iPadmini 未満--
  /====================================================================*/
}
@media (max-width: 575px) {
  /*====================================================================/
  /*--iPhone 以下--
  /====================================================================*/
}
@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
  /*--スマホ以上 タブレット iPadmini未満--
  /====================================================================*/
}
@media (min-width: 768px) and (max-width: 1175px) {
  /*====================================================================/
  /*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
  /====================================================================*/
}
@media (min-width: 1176px) and (max-width: 1552px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro以上 PC未満--
  /====================================================================*/
}
@media (min-width: 1553px) and (max-width: 1752px) {
  /*====================================================================/
  /*--px以上 Large PC未満--
  /====================================================================*/
}
/*====================================================================

index.css

=====================================================================*/
/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
}
@media (min-width: 768px) {
  .main-view {
    background-image: url(../img/top/main_bg_tb.jpg);
    background-size: cover;
    height: 71.4vw;
  }
}
@media (min-width: 1176px) {
  .main-view {
    background-image: url(../img/top/main_bg_pc.jpg);
    height: 54vw;
  }
}
@media (min-width: 1553px) {
  .main-view {
    height: 850px;
  }
}
@media (min-width: 1921px) {
  .main-view {
    height: 44.27vw;
  }
}
@media (max-width: 767px) {
  .main-view {
    background-image: url(../img/top/main_bg_sp.jpg);
    background-size: cover;
    height: 125vw;
  }
}
.main-view .l-cont {
  margin-left: 0;
  margin-right: 0;
}
.main-view__catch01 {
  position: absolute;
  width: clamp(160px, 72vw, 1100px);
  top: 14.5%;
  left: 12.5%;
}
@media (min-width: 768px) {
  .main-view__catch01 {
    top: 20%;
    left: 15%;
    width: clamp(480px, 70vw, 1100px);
  }
}
@media (min-width: 1176px) {
  .main-view__catch01 {
    top: 20%;
    left: 19.35%;
    width: clamp(640px, 57.29vw, 1100px);
  }
}
@media (min-width: 1553px) {
  .main-view__catch01 {
    top: 13.5%;
    left: 19.35%;
    width: min(57.29vw, 1100px);
  }
}
.main-view__catch02 {
  position: absolute;
  top: 56%;
  left: 0;
  right: 0;
  margin: auto;
  width: clamp(140px, 66vw, 704px);
}
@media (min-width: 768px) {
  .main-view__catch02 {
    top: 62%;
    width: clamp(400px, 36.66vw, 704px);
  }
}
@media (min-width: 1176px) {
  .main-view__catch02 {
    top: 58%;
    width: clamp(600px, 36.66vw, 704px);
  }
}
@media (min-width: 1553px) {
  .main-view__catch02 {
    top: 62%;
  }
}
.main-view__books {
  position: absolute;
  right: 2%;
  width: 36%;
  z-index: 2;
  bottom: -1em;
}
@media (min-width: 768px) {
  .main-view__books {
    width: 20%;
    bottom: 4em;
  }
}
@media (min-width: 1176px) {
  .main-view__books {
    width: 22%;
  }
}
@media (min-width: 1553px) {
  .main-view__books {
    width: min(19.48%, 374px);
  }
}
@media (min-width: 1753px) {
  .main-view__books {
    bottom: -2em;
  }
}
.main-view__left {
  position: absolute;
  width: 44%;
  left: 3%;
  bottom: -0.5em;
  z-index: 3;
}
@media (min-width: 768px) {
  .main-view__left {
    width: 18.23%;
    top: 11%;
    left: 1%;
    bottom: auto;
    width: 20%;
  }
}
@media (min-width: 1176px) {
  .main-view__left {
    top: 13%;
  }
}
@media (min-width: 1553px) {
  .main-view__left {
    width: 18.23%;
    top: 11%;
    left: 3%;
  }
}
.main-view__right {
  position: absolute;
  width: 22.5%;
  top: 40%;
  right: -2%;
}
@media (min-width: 768px) {
  .main-view__right {
    width: 20%;
    top: 28%;
    right: -5%;
  }
}
@media (min-width: 1176px) {
  .main-view__right {
    width: 19%;
    top: 17%;
    right: 1%;
  }
}
@media (min-width: 1553px) {
  .main-view__right {
    width: 17.5%;
    right: 3.5%;
  }
}
.main-view__num {
  position: absolute;
  top: 40%;
  left: -1%;
}
@media (min-width: 768px) {
  .main-view__num {
    top: 50%;
    left: 1%;
  }
}
@media (min-width: 1176px) {
  .main-view__num {
    top: 48%;
    left: 2%;
  }
}
@media (min-width: 1553px) {
  .main-view__num {
    top: 50%;
    left: auto;
    right: 70%;
  }
}
.main-view__num__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../img/common/frame_bg01.svg) center center/contain no-repeat;
  width: 26vw;
  height: 24vw;
}
@media (min-width: 768px) {
  .main-view__num__inner {
    width: 20vw;
    height: 18.6vw;
  }
}
@media (min-width: 1176px) {
  .main-view__num__inner {
    width: 21vw;
    height: 20.3vw;
  }
}
@media (min-width: 1553px) {
  .main-view__num__inner {
    width: 300px;
    height: 290px;
  }
}
.main-view__num__inner > div {
  line-height: 1.2;
  text-align: center;
}
.main-view__num p {
  font-weight: 800;
  letter-spacing: 0.02em;
  font-size: calc(0.45rem + 18 * (100vw - 280px) / 1160);
}
@media (min-width: 768px) {
  .main-view__num p {
    font-size: calc(0.8rem + 6 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1176px) {
  .main-view__num p {
    font-size: calc(1.14rem + 6 * (100vw - 1080px) / 360);
  }
}
@media (min-width: 1553px) {
  .main-view__num p {
    font-size: 1.71rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .main-view__num p {
    font-size: 1.6rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .main-view__num p {
    font-size: 1.5rem;
  }
}
.main-view__num p.is-number b {
  font-family: 'Abril Fatface', sans-serif;
  font-size: 3em;
  color: #E86607;
  font-weight: 400;
}
.main-view__num p.is-number small {
  font-size: 1.5em;
  padding-left: 0.1em;
}

/* common
----------------------------------------------------------------*/
/*--------------------------------------------------------------------/
	contents
/--------------------------------------------------------------------*/
.main {
  overflow: hidden;
}

.contents {
  background: #F6F6F8 url(../img/common/bg_gray.png) 0 0 repeat;
  position: relative;
}

/* top-news */
.top-news {
  position: relative;
  top: 2em;
  z-index: 4;
}
@media (min-width: 768px) {
  .top-news {
    top: -2em;
  }
}
.top-news__inner {
  position: relative;
  max-width: 1000px;
  border-radius: clamp(6px, 2vw, 30px);
  margin-left: auto;
  margin-right: auto;
  padding: clamp(1.5em, 2vw, 2em) clamp(1.5em, 5%, 3em);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  background: #fff;
  z-index: 2;
}
@media (min-width: 1176px) {
  .top-news__inner {
    padding: clamp(2em, 3vw, 3em) clamp(1.5em, 5%, 3em);
  }
}
.top-news__grid {
  display: grid;
}
@media (min-width: 768px) {
  .top-news__grid {
    grid-template-columns: 180px 1fr;
    column-gap: clamp(2em, 4vw, 4em);
  }
}
.top-news__tit {
  line-height: 1;
}
@media (min-width: 768px) {
  .top-news__tit {
    margin-bottom: clamp(2em, 3vw, 3em);
    grid-column: 1/2;
    grid-row: 1/2;
  }
}
@media (max-width: 767px) {
  .top-news__tit {
    margin-bottom: 1em;
  }
}
.top-news__tit b {
  font-family: 'Rubik', sans-serif;
  display: block;
  color: #54BA00;
  font-weight: 500;
  font-size: calc(2.5rem + 24 * (100vw - 280px) / 1160);
  margin-bottom: 0.2em;
}
@media (min-width: 768px) {
  .top-news__tit b {
    font-size: 3.57rem;
  }
}
@media (min-width: 768px) and (min-width: 576px) {
  .top-news__tit b {
    font-size: 3.33rem;
  }
}
@media (min-width: 768px) and (min-width: 1176px) {
  .top-news__tit b {
    font-size: 3.13rem;
  }
}
@media (min-width: 1553px) {
  .top-news__tit b {
    font-size: 3.86rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .top-news__tit b {
    font-size: 3.6rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .top-news__tit b {
    font-size: 3.38rem;
  }
}
.top-news__tit small {
  display: block;
  font-weight: 500;
  font-size: calc(1.25rem + 12 * (100vw - 280px) / 1160);
}
@media (min-width: 768px) {
  .top-news__tit small {
    font-size: 1.71rem;
  }
}
@media (min-width: 768px) and (min-width: 576px) {
  .top-news__tit small {
    font-size: 1.6rem;
  }
}
@media (min-width: 768px) and (min-width: 1176px) {
  .top-news__tit small {
    font-size: 1.5rem;
  }
}
@media (min-width: 768px) {
  .top-news__list {
    grid-column: 2/3;
    grid-row: 1/3;
  }
}
.top-news__list__inner {
  max-height: 200px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #C9CED2 #fff;
}
.top-news__list__inner::-webkit-scrollbar {
  width: 8px;
}
.top-news__list__inner::-webkit-scrollbar-track {
  background: transparent;
}
.top-news__list__inner::-webkit-scrollbar-thumb {
  background: #C9CED2;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px #fff;
}
.top-news__list__inner > li {
  position: relative;
  border-bottom: 1px solid #D9D9D9;
}
.top-news__list__inner > li:first-child {
  border-top: 1px solid #D9D9D9;
}
.top-news__list__inner > li::after {
  position: absolute;
  bottom: -1px;
  left: 0;
  content: "";
  width: 0;
  height: 1px;
  background: #12A40A;
  transition: all 0.3s ease 0s;
  z-index: 2;
}
.top-news__list__inner > li:hover::after {
  width: 100%;
}
.top-news__list__inner a {
  text-decoration: none !important;
  color: #202020;
  display: grid;
  padding: 1em 0;
  line-height: 1.4;
}
@media (min-width: 576px) {
  .top-news__list__inner a {
    column-gap: 1em;
    grid-template-columns: 86px auto 1fr;
  }
}
@media (max-width: 575px) {
  .top-news__list__inner a {
    grid-template-columns: 80px 1fr;
    grid-template-rows: auto 1fr;
    column-gap: 0.5em;
    row-gap: 0.3em;
  }
}
.top-news__list__date {
  font-weight: 700;
  letter-spacing: 0;
  font-size: 1.14em;
  grid-column: 1/2;
}
.top-news__list__category {
  grid-column: 2/3;
  display: flex;
  flex-wrap: wrap;
  gap: 0.2em;
}
.top-news__list__category li span {
  font-size: 0.9em;
}
@media (min-width: 576px) {
  .top-news__list__tit {
    grid-column: 3/4;
    font-size: 1.14em;
  }
}
@media (max-width: 575px) {
  .top-news__list__tit {
    grid-column: 1/3;
    grid-row: 2/3;
  }
}
@media (min-width: 768px) {
  .top-news__btn {
    grid-column: 1/2;
    grid-row: 2/3;
  }
}
@media (max-width: 767px) {
  .top-news__btn {
    margin-left: auto;
    margin-right: auto;
    width: clamp(240px, 100%, 360px);
    margin-top: clamp(2em, 3vw, 3em);
  }
}
.top-news__btn a {
  font-size: 1em;
  padding: 0.75em 3%;
}

/* top-about */
.top-about {
  padding-top: clamp(3em, 6vw, 6em);
  padding-bottom: clamp(2em, 4vw, 4em);
}
@media (min-width: 768px) {
  .top-about__inner {
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
}
@media (min-width: 1553px) {
  .top-about__inner {
    min-height: 640px;
  }
}
@media (max-width: 1175px) {
  .top-about__inner {
    display: flex;
    flex-direction: column-reverse;
    padding-left: 5%;
    padding-right: 5%;
  }
}

.top-about__txt {
  position: relative;
  z-index: 2;
  background: #fff;
}
@media (min-width: 1176px) {
  .top-about__txt {
    width: 60%;
    padding: 0 clamp(3em, 6vw, 6em) clamp(3em, 6vw, 6em) clamp(6em, 16%, 16em);
    border-top-left-radius: clamp(8px, 2.5vw, 40px);
    border-bottom-left-radius: clamp(8px, 2.5vw, 40px);
  }
}
@media (min-width: 1553px) {
  .top-about__txt {
    padding-left: clamp(6em, 18%, 18em);
  }
}
@media (max-width: 1175px) {
  .top-about__txt {
    margin-top: clamp(-4.5em, 6.5vw, -6.5em);
    width: 100%;
    padding: 0 clamp(1.5em, 5%, 4.5em) clamp(2em, 3vw, 3em);
    border-radius: clamp(8px, 2.5vw, 40px);
  }
}
.top-about__txt__inner {
  position: relative;
}
@media (min-width: 1176px) {
  .top-about__txt__inner {
    padding-top: clamp(3em, 5vw, 5em);
    max-width: 620px;
  }
}
@media (max-width: 1175px) {
  .top-about__txt__inner {
    padding-top: clamp(6em, 8vw, 8em);
  }
}
.top-about__txt__tit {
  color: #4CAE47;
  font-weight: 700;
  line-height: 1;
  font-size: calc(1.25rem + 40 * (100vw - 280px) / 1160);
}
@media (min-width: 1176px) {
  .top-about__txt__tit {
    position: absolute;
    top: -0.5em;
    font-size: calc(2rem + 24 * (100vw - 1080px) / 360);
  }
}
@media (min-width: 1553px) {
  .top-about__txt__tit {
    font-size: 4.29rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .top-about__txt__tit {
    font-size: 4rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .top-about__txt__tit {
    font-size: 3.75rem;
  }
}
@media (max-width: 1175px) {
  .top-about__txt__tit {
    margin-bottom: 0.75em;
  }
}
.top-about__txt__catch {
  font-size: calc(1.14rem + 12 * (100vw - 280px) / 1160);
  font-weight: 700;
  padding-bottom: clamp(0.5em, 0.75vw, 0.75em);
  margin-bottom: clamp(0.75em, 1vw, 1em);
  border-bottom: 0.3em dotted #4CAE47;
}
@media (min-width: 1176px) {
  .top-about__txt__catch {
    font-size: calc(1.14rem + 12 * (100vw - 1080px) / 360);
  }
}
@media (min-width: 1553px) {
  .top-about__txt__catch {
    font-size: 2.29rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .top-about__txt__catch {
    font-size: 2.13rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .top-about__txt__catch {
    font-size: 2rem;
  }
}
@media (min-width: 768px) {
  .top-about__txt__txt {
    line-height: 2;
  }
}
@media (min-width: 1553px) {
  .top-about__txt__txt {
    font-size: 1.29rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .top-about__txt__txt {
    font-size: 1.2rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .top-about__txt__txt {
    font-size: 1.13rem;
  }
}
.top-about__txt__btn {
  width: clamp(240px, 100%, 360px);
  margin-top: clamp(2em, 3vw, 3em);
}
@media (min-width: 1176px) {
  .top-about__txt__btn {
    margin-top: clamp(3em, 4vw, 4em);
  }
}
@media (max-width: 767px) {
  .top-about__txt__btn {
    margin-left: auto;
    margin-right: auto;
  }
}
.top-about__txt__btn a {
  font-size: 1em;
  padding: 0.75em 3%;
}

.top-about__video {
  position: relative;
  z-index: 2;
  width: 90%;
}
@media (min-width: 1176px) {
  .top-about__video {
    position: absolute;
    top: 4em;
    right: 50%;
    width: 45%;
  }
}
@media (min-width: 1553px) {
  .top-about__video {
    width: clamp(500px, 49%, 800px);
  }
}
@media (max-width: 1175px) {
  .top-about__video {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }
}
.top-about__video__inner {
  padding: clamp(1.5em, 3vw, 3em) clamp(2em, 8%, 4em);
  background: #FFF9E5;
  border-radius: clamp(6px, 2vw, 30px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.top-about__video__video {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.top-about__video__video iframe {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
}
.top-about__video__tit {
  margin-top: 1em;
}
.top-about__video__tit span {
  position: relative;
  color: #202020;
  line-height: 1.4;
  font-weight: 700;
  padding: 1em 1.5em 1em 0;
  background: #F2E700;
  display: inline-block;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
@media (min-width: 576px) {
  .top-about__video__tit span {
    padding: 1.25em 1.5em 1.25em 0;
  }
}
.top-about__video__tit span::before {
  position: absolute;
  top: 0;
  right: calc(100% - 1px);
  content: "";
  width: clamp(2.5em, 12vw, 4.5rem);
  height: 100%;
  background: #F2E700;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
@media (min-width: 1176px) {
  .top-about__video__tit span::before {
    width: 5em;
  }
}

/* top-sketch */
.top-sketch {
  position: relative;
}
.top-sketch::before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 70%;
  background: #92D459;
}
.top-sketch::after {
  position: absolute;
  left: 0;
  bottom: calc(70% - 1px);
  content: "";
  width: 100vw;
  height: 13.125vw;
  background: url(../img/common/common_wave_green.svg) left bottom/cover no-repeat;
}
.top-sketch__inner {
  position: relative;
  padding-top: clamp(9em, 18vw, 18em);
  padding-bottom: clamp(6em, 9vw, 9em);
  z-index: 1;
}
@media (min-width: 1176px) {
  .top-sketch__inner::before {
    position: absolute;
    left: -2%;
    bottom: 2em;
    content: "";
    width: clamp(96px, 23%, 430px);
    height: clamp(200px, 47vw, 892px);
    background: url(../img/top/top-sketch_bg01.png) top center/contain no-repeat;
  }
}
@media (min-width: 1176px) {
  .top-sketch__inner::after {
    position: absolute;
    right: 3%;
    bottom: 2em;
    content: "";
    width: clamp(114px, 19.8%, 380px);
    height: clamp(150px, 26vw, 500px);
    background: url(../img/top/top-sketch_bg02.png) top center/contain no-repeat;
  }
}

.top-sketch__txt {
  position: relative;
  max-width: 1100px;
  border-radius: clamp(4px, 1.5vw, 16px);
  padding-top: clamp(1em, 2vw, 2em);
  background: #fff;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.top-sketch__txt__add {
  position: relative;
  top: -1em;
  width: 100%;
}
.top-sketch__txt__inner {
  margin-left: auto;
  margin-right: auto;
  padding: clamp(2em, 3vw, 3em) clamp(1.5em, 5%, 4.5em) clamp(3em, 6vw, 6em);
}
.top-sketch__txt__img {
  position: absolute;
  bottom: calc(100% - 3em);
  left: -4%;
  width: clamp(160px, 36%, 320px);
  z-index: 3;
}
@media (min-width: 1176px) {
  .top-sketch__txt__img {
    bottom: calc(100% - 2em);
    width: clamp(200px, 48%, 456px);
  }
}
.top-sketch__txt__pic {
  position: absolute;
}
@media (min-width: 1176px) {
  .top-sketch__txt__pic {
    width: clamp(120px, 32.72%, 360px);
    bottom: -5em;
    right: -12%;
  }
}
@media (min-width: 1553px) {
  .top-sketch__txt__pic {
    bottom: auto;
    top: 20%;
    right: -26%;
  }
}
@media (max-width: 1175px) {
  .top-sketch__txt__pic {
    display: none;
  }
}
.top-sketch__txt__tit {
  position: relative;
  max-width: 876px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: clamp(1.5em, 3vw, 3em);
}
.top-sketch__txt__tit__add {
  position: absolute;
  width: clamp(120px, 50%, 440px);
  bottom: calc(100% + 1em);
  left: 60%;
}
.top-sketch__txt__catch {
  text-align: center;
  font-size: calc(1.14rem + 12 * (100vw - 280px) / 1160);
  line-height: 1.4;
  margin-bottom: clamp(1.25em, 1.75vw, 2em);
  text-align: center;
  border-radius: 5px;
}
@media (min-width: 1553px) {
  .top-sketch__txt__catch {
    font-size: 2.14rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .top-sketch__txt__catch {
    font-size: 2rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .top-sketch__txt__catch {
    font-size: 1.88rem;
  }
}
.top-sketch__txt__catch span {
  display: inline-block;
  padding: 0.5em 1em;
  color: #fff;
  background: #4CAE47;
}
.top-sketch__txt__txt {
  line-height: 2;
  font-size: calc(0.9rem + 3 * (100vw - 280px) / 1160);
}
@media (min-width: 768px) {
  .top-sketch__txt__txt {
    text-align: center;
  }
}
@media (min-width: 1553px) {
  .top-sketch__txt__txt {
    font-size: 1.36rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .top-sketch__txt__txt {
    font-size: 1.27rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .top-sketch__txt__txt {
    font-size: 1.19rem;
  }
}
.top-sketch__txt__btn {
  min-width: 240px;
  margin-top: clamp(3em, 4vw, 4em);
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}

/* top-anxiety */
.top-anxiety {
  position: relative;
  background: #FFF9E5;
  padding-bottom: clamp(5em, 8vw, 8em);
}
.top-anxiety__wrap {
  position: relative;
  padding-top: clamp(2em, 4vw, 4em);
  padding-bottom: clamp(5em, 8vw, 8em);
  background: #fff;
  border-bottom-left-radius: clamp(8px, 16vw, 200px);
  border-bottom-right-radius: clamp(8px, 16vw, 200px);
}
.top-anxiety__wrap::after {
  position: absolute;
  top: calc(100% - 1px);
  left: calc(50% - 2em);
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3em 2em 0 2em;
  border-color: #fff transparent transparent transparent;
}
@media (min-width: 1176px) {
  .top-anxiety__wrap::after {
    left: calc(50% - 4em);
    border-width: 5em 4em 0 4em;
  }
}
.top-anxiety__tit {
  display: flex;
  margin-bottom: clamp(2em, 4vw, 4em);
}
@media (min-width: 768px) {
  .top-anxiety__tit {
    column-gap: 6%;
    align-items: baseline;
  }
}
@media (max-width: 767px) {
  .top-anxiety__tit {
    flex-direction: column;
    row-gap: clamp(0.5em, 1vw, 1em);
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 768px) {
  .top-anxiety__tit__add {
    width: 42.5%;
  }
}
@media (max-width: 767px) {
  .top-anxiety__tit__add {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 768px) {
  .top-anxiety__tit__txt {
    width: 51%;
  }
}
.top-anxiety__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  column-gap: 2%;
  row-gap: 1em;
}
@media (min-width: 768px) {
  .top-anxiety__list {
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    column-gap: 2%;
    row-gap: 1em;
  }
}
@media (min-width: 1176px) {
  .top-anxiety__list {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 1.5em;
  }
}

.top-anxiety__box {
  border: 1px solid #4B4D4E;
  border-radius: clamp(6px, 2vw, 30px);
}
.top-anxiety__box:nth-of-type(4n + 1) {
  background: #EDFFE1;
}
.top-anxiety__box:nth-of-type(4n + 2) {
  background: #FEFDE6;
}
.top-anxiety__box:nth-of-type(4n + 3) {
  background: #FDF0E6;
}
.top-anxiety__box:nth-of-type(4n + 4) {
  background: #F2FBEC;
}
.top-anxiety__box__inner {
  padding: clamp(1em, 1.5vw, 1.5em) clamp(1.5em, 2.5vw, 2.5em);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  column-gap: 1.5em;
}
.top-anxiety__box__txt {
  width: 1fr;
  flex-grow: 1;
  flex-shrink: 1;
  font-weight: 700;
  line-height: 1.5;
}
@media (min-width: 1553px) {
  .top-anxiety__box__txt {
    font-size: 1.14em;
  }
}
.top-anxiety__box__img {
  width: clamp(40px, 14%, 68px);
  flex-shrink: 0;
}
.top-anxiety__box__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
}
.top-anxiety__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 158.82%;
  position: relative;
}
.top-anxiety__box__img__inner > div,
.top-anxiety__box__img__inner figure,
.top-anxiety__box__img__inner a,
.top-anxiety__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/* .top-service */
.top-service {
  background: #FFF9E5;
}
.top-service__head__tit {
  margin-left: auto;
  margin-right: auto;
  width: clamp(200px, 100%, 1100px);
}
.top-service__txt {
  position: relative;
  background: rgba(76, 174, 71, 0.7);
  margin-top: clamp(3em, 6vw, 6em);
  padding-bottom: clamp(3em, 8vw, 8em);
}
.top-service__txt::before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 75%;
  background: #4CAE47;
}
@media (min-width: 1553px) {
  .top-service__txt::before {
    height: calc(100% - 20em);
  }
}
.top-service__txt__inner {
  position: relative;
  padding-top: clamp(4.75em, 7vw, 8em);
  z-index: 2;
}
.top-service__txt__tit {
  position: absolute;
  top: -1.5em;
  left: 0;
  right: 0;
  text-align: center;
  font-size: calc(0.94rem + 12 * (100vw - 280px) / 1160);
}
@media (min-width: 1553px) {
  .top-service__txt__tit {
    font-size: 2.29rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .top-service__txt__tit {
    font-size: 2.13rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .top-service__txt__tit {
    font-size: 2rem;
  }
}
.top-service__txt__tit span {
  display: inline-block;
  padding: 0.75em 1em;
  font-weight: 700;
  background: #fff;
  border-radius: 5px;
}
@media (min-width: 768px) {
  .top-service__txt__tit span {
    padding: 0.75em 1.75em;
  }
}
.top-service__txt__list {
  margin-left: auto;
  margin-right: auto;
  max-width: 1240px;
  display: grid;
  row-gap: 2.5em;
  counter-reset: plan-num;
}
@media (min-width: 768px) {
  .top-service__txt__list {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    column-gap: 2%;
  }
}
@media (min-width: 1176px) {
  .top-service__txt__list {
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    column-gap: 2.8%;
  }
}
.top-service__txt__txt {
  margin-top: clamp(2em, 3vw, 3em);
  color: #fff;
  font-size: calc(0.94rem + 8 * (100vw - 280px) / 1160);
}
@media (min-width: 1176px) {
  .top-service__txt__txt {
    text-align: center;
  }
}
@media (min-width: 1553px) {
  .top-service__txt__txt {
    font-size: 1.71rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .top-service__txt__txt {
    font-size: 1.6rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .top-service__txt__txt {
    font-size: 1.5rem;
  }
}
.top-service__txt__btn {
  min-width: 240px;
  margin-top: clamp(3em, 4vw, 4em);
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}
.top-service__txt__btn a {
  font-size: 1em;
  padding: 0.75em 3%;
}

.top-service__box {
  position: relative;
  padding: clamp(1.5em, 3vw, 3em) clamp(1.5em, 5%, 2em) clamp(1.5em, 3vw, 3em);
  content: "";
  background: #fff;
  border-radius: clamp(8px, 6vw, 96px);
}
@media (min-width: 768px) {
  .top-service__box {
    padding: clamp(1.5em, 3vw, 3em) clamp(1em, 5%, 2em) clamp(1.25em, 3vw, 3em);
  }
}
@media (min-width: 1176px) {
  .top-service__box {
    padding: clamp(1.5em, 3vw, 3em) clamp(2em, 8%, 4.5em) clamp(1.5em, 3vw, 3em);
  }
}
.top-service__box::before {
  position: absolute;
  bottom: calc(100% - 0.5em);
  left: 50%;
  transform: translateX(-50%);
  color: #FDD035;
  font-family: 'Rubik', sans-serif;
  letter-spacing: 0;
  line-height: 1;
  counter-increment: plan-num;
  content: counter(plan-num, decimal-leading-zero);
  font-weight: 500;
  font-size: calc(3rem + 48 * (100vw - 280px) / 1160);
}
@media (min-width: 768px) {
  .top-service__box::before {
    font-size: calc(4rem + 16 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1176px) {
  .top-service__box::before {
    font-size: 5em;
  }
}
.top-service__box__inner {
  display: flex;
  flex-direction: column-reverse;
  row-gap: 0.75em;
}
.top-service__box__txt {
  text-align: center;
  font-weight: 700;
  line-height: 1.5;
  font-size: calc(1rem + 8 * (100vw - 280px) / 1160);
}
@media (min-width: 768px) {
  .top-service__box__txt {
    font-size: calc(0.8rem + 6 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1553px) {
  .top-service__box__txt {
    font-size: 1.57rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .top-service__box__txt {
    font-size: 1.47rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .top-service__box__txt {
    font-size: 1.38rem;
  }
}
.top-service__box__img {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 320px;
}
.top-service__box__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
}
.top-service__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 64.91%;
  position: relative;
}
.top-service__box__img__inner > div,
.top-service__box__img__inner figure,
.top-service__box__img__inner a,
.top-service__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/* top-reserve */
.top-reserve {
  padding-top: clamp(2em, 4vw, 4em);
  padding-bottom: clamp(3em, 6vw, 6em);
  background: #FFF9E5;
}
@media (max-width: 767px) {
  .top-reserve {
    padding-bottom: clamp(7em, 12vw, 12em);
  }
}
.top-reserve .l-cont {
  position: relative;
}
.top-reserve__add {
  position: absolute;
}
@media (min-width: 768px) {
  .top-reserve__add {
    top: 0;
    width: 21.8%;
  }
}
@media (max-width: 767px) {
  .top-reserve__add {
    bottom: clamp(-7em, -12vw, -12em);
    width: 20vw;
  }
}
.top-reserve__add.is-add01 {
  left: 2%;
}
.top-reserve__add.is-add02 {
  right: 2%;
}
@media (min-width: 768px) {
  .top-reserve__inner {
    margin-left: auto;
    margin-right: auto;
    width: 56%;
  }
}
.top-reserve__tit {
  width: 94%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: clamp(0.75em, 1.5vw, 1.5em);
}
.top-reserve__txt {
  margin-bottom: 0.75em;
  text-align: center;
}
@media (min-width: 768px) {
  .top-reserve__txt {
    font-size: calc(1rem + 4 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1553px) {
  .top-reserve__txt {
    font-size: 1.71rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .top-reserve__txt {
    font-size: 1.6rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .top-reserve__txt {
    font-size: 1.5rem;
  }
}
.top-reserve__btn {
  margin-left: auto;
  margin-right: auto;
  max-width: 360px;
}
@media (min-width: 768px) {
  .top-reserve__btn {
    max-width: 700px;
  }
}
.top-reserve__btn a {
  font-weight: 500;
  display: block;
  text-align: center;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  display: grid;
  color: #fff;
  background-color: #E86607;
  border: 1px solid;
  border-color: #E86607;
  padding: 1em 3%;
  font-size: 1.25em;
  width: 100%;
  font-weight: 700;
  border-radius: 999px;
}
.top-reserve__btn a span {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 0.5em;
  align-items: center;
  transition: all 0.3s ease 0s;
}
.top-reserve__btn a span:after {
  content: "\e914";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.top-reserve__btn a span::before {
  content: "";
  justify-self: start;
}
.top-reserve__btn a span::after {
  justify-self: end;
  font-size: 1.25em;
  transition: all 0.3s ease 0s;
  color: #202020;
  width: 2em;
  height: 2em;
  line-height: 2em;
  border-radius: 50%;
  background: #fff;
}
.top-reserve__btn a:hover {
  color: #fff;
  background-color: #ed4d0e;
  border-color: #ed4d0e;
}
.top-reserve__btn a:hover span::after {
  color: #ed4d0e;
}
@media (min-width: 768px) {
  .top-reserve__btn a {
    font-size: calc(1.25rem + 10 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1553px) {
  .top-reserve__btn a {
    font-size: 2.29rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .top-reserve__btn a {
    font-size: 2.13rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .top-reserve__btn a {
    font-size: 2rem;
  }
}

/* top-number */
.top-number {
  padding-bottom: clamp(3em, 6vw, 6em);
  background: #fff;
}
.top-number__inner {
  position: relative;
}
.top-number__separate {
  display: flex;
}
.top-number__separate > * {
  width: 50%;
}
.top-number__notes {
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
}
.top-number__notes p {
  padding: 0.4em 1em;
  background: #F0F3F4;
}

.top-number__item {
  overflow: hidden;
  position: relative;
}
.top-number__item::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: 1;
}
.top-number__item.is-sketch {
  background: url(../img/top/number_bg01.jpg) center center/cover no-repeat;
}
.top-number__item.is-sketch::before {
  background: rgba(237, 143, 28, 0.8);
}
.top-number__item.is-consultation {
  background: url(../img/top/number_bg02.jpg) center center/cover no-repeat;
}
.top-number__item.is-consultation::before {
  background: rgba(14, 149, 7, 0.8);
}
.top-number__item__inner {
  position: relative;
  width: 100%;
  padding-top: 36vw;
  color: #fff;
  z-index: 3;
}
@media (min-width: 768px) {
  .top-number__item__inner {
    padding-top: 25vw;
  }
}
@media (min-width: 1753px) {
  .top-number__item__inner {
    padding-top: 40%;
  }
}
.top-number__item__inner::before {
  position: absolute;
  top: 2vw;
  left: 2vw;
  right: 2vw;
  bottom: 2vw;
  content: "";
  width: calc(100% - 4vw);
  height: calc(100% - 4vw);
  border: 1px solid #fff;
}
@media (min-width: 768px) {
  .top-number__item__inner::before {
    top: 1em;
    left: 1em;
    right: 1em;
    bottom: 1em;
    width: calc(100% - 2em);
    height: calc(100% - 2em);
    border-width: 2px;
  }
}
.top-number__item__txt {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-number__item__txt__inner {
  text-align: center;
}
.top-number__item__txt__tit {
  font-size: clamp(8px, 2.86vw, 32px);
}
.top-number__item__txt__num {
  line-height: 1;
}
.top-number__item__txt__num b {
  font-weight: 700;
  font-size: clamp(24px, 8.57vw, 120px);
}
.top-number__item__txt__num small {
  font-weight: 700;
  font-size: clamp(16px, 5.71vw, 56px);
}

/* top-consultation */
.top-consultation {
  position: relative;
  overflow: hidden;
  padding-top: clamp(2em, 4vw, 4em);
  padding-bottom: clamp(2em, 4vw, 4em);
  background: #fff;
}
.top-consultation::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 90%;
  height: 100%;
  border-top-right-radius: clamp(8px, 2.5vw, 40px);
  border-bottom-right-radius: clamp(8px, 2.5vw, 40px);
  background: #FFF9E5;
}
.top-consultation__tit {
  position: relative;
  font-size: calc(1.25rem + 20 * (100vw - 280px) / 1160);
  font-weight: 700;
  padding-left: 1em;
  margin-bottom: clamp(1em, 1.5vw, 2em);
}
@media (min-width: 1553px) {
  .top-consultation__tit {
    font-size: 2.86rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .top-consultation__tit {
    font-size: 2.67rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .top-consultation__tit {
    font-size: 2.5rem;
  }
}
.top-consultation__tit::before {
  position: absolute;
  top: 0.6em;
  left: 0;
  content: "";
  width: 0.8em;
  height: 0.15em;
  border-radius: 10px;
  background: #4CAE47;
}
.top-consultation__inner {
  position: relative;
  z-index: 3;
}
.top-consultation__list {
  margin-bottom: clamp(2rem, 5vw, 5rem);
}
@media (min-width: 1176px) {
  .top-consultation__btn {
    position: absolute;
    top: -1em;
    right: 0;
    min-width: 240px;
  }
}
@media (max-width: 1175px) {
  .top-consultation__btn {
    width: 100%;
    max-width: 430px;
    margin-top: clamp(3em, 4vw, 4em);
    margin-left: auto;
    margin-right: auto;
  }
}
.top-consultation__btn a {
  font-size: 1em;
  padding: 0.75em 3%;
}

.top-consultation .swiper {
  width: 100%;
  overflow: visible;
  position: relative;
  margin-bottom: 5rem;
}
@media (min-width: 1553px) {
  .top-consultation .swiper {
    margin-bottom: 7rem;
  }
}
.top-consultation .swiper-control {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 1440 px;
}
@media (min-width: 1921px) {
  .top-consultation .swiper-control {
    max-width: 75vw;
  }
}
.top-consultation .swiper-next_prev {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: calc(100% + 32px);
  left: -16px;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
}
@media (min-width: 768px) {
  .top-consultation .swiper-next_prev {
    width: calc(100% + 64px);
    left: -32px;
  }
}
@media (min-width: 1176px) {
  .top-consultation .swiper-next_prev {
    width: calc(100% + 90px);
    left: -45px;
  }
}
.top-consultation .swiper-button-prev {
  left: 0;
}
.top-consultation .swiper-button-next {
  right: 0;
}
.top-consultation .swiper-button-prev,
.top-consultation .swiper-button-next {
  margin: 0;
  float: none;
  display: block;
  background-image: none;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}
@media (min-width: 768px) {
  .top-consultation .swiper-button-prev,
  .top-consultation .swiper-button-next {
    width: 64px;
    height: 64px;
  }
}
@media (min-width: 1176px) {
  .top-consultation .swiper-button-prev,
  .top-consultation .swiper-button-next {
    width: 90px;
    height: 90px;
  }
}
.top-consultation .swiper-button-prev::before,
.top-consultation .swiper-button-next::before {
  content: "";
  position: absolute;
  background: #fff;
  border: 1px solid #F8F9FA;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease 0s;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.top-consultation .swiper-button-prev::after,
.top-consultation .swiper-button-next::after {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #4CAE47;
  font-size: 1.5em;
  transition: all 0.3s ease 0s;
  z-index: 3;
}
@media (min-width: 1176px) {
  .top-consultation .swiper-button-prev::after,
  .top-consultation .swiper-button-next::after {
    font-size: 3.43rem;
  }
}
@media (min-width: 1176px) and (min-width: 576px) {
  .top-consultation .swiper-button-prev::after,
  .top-consultation .swiper-button-next::after {
    font-size: 3.2rem;
  }
}
@media (min-width: 1176px) and (min-width: 1176px) {
  .top-consultation .swiper-button-prev::after,
  .top-consultation .swiper-button-next::after {
    font-size: 3rem;
  }
}
.top-consultation .swiper-button-prev:hover::before,
.top-consultation .swiper-button-next:hover::before {
  background: #12A40A;
  border-color: #12A40A;
}
.top-consultation .swiper-button-prev:hover::after,
.top-consultation .swiper-button-next:hover::after {
  color: #fff;
}
.top-consultation .swiper-button-prev:after {
  content: "\e902";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.top-consultation .swiper-button-prev::after {
  transform: translate(-60%, -50%);
}
.top-consultation .swiper-button-next:after {
  content: "\e901";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.top-consultation .swiper-button-next::after {
  transform: translate(-40%, -50%);
}
.top-consultation .swiper-scrollbar {
  position: absolute;
  top: calc(100% + 2rem);
  width: 100%;
  height: 6px;
  z-index: 1;
  background: #F8F9FA;
}
@media (min-width: 1176px) {
  .top-consultation .swiper-scrollbar {
    height: 8px;
    top: calc(100% + 3rem);
  }
}
.top-consultation .swiper-scrollbar-drag {
  background: #C9CED2;
  height: 6px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}
@media (min-width: 768px) {
  .top-consultation .swiper-scrollbar-drag {
    height: 8px;
  }
}
.top-consultation .swiper-scrollbar-drag:hover {
  background-color: #54BA00;
}

/* top-books */
.top-books {
  padding-top: clamp(3em, 6vw, 6em);
  padding-bottom: clamp(5em, 6vw, 6em);
  background: #fff;
}
.top-books__inner {
  background: #F6F6F8 url(../img/common/bg_gray.png) 0 0 repeat;
  border-radius: clamp(8px, 2.5vw, 40px);
  padding: clamp(1.5em, 3vw, 3em) 0 clamp(2em, 4.5vw, 4.5em);
  border: 1px solid #4CAE47;
  display: flex;
}
@media (min-width: 768px) {
  .top-books__inner {
    padding: clamp(2em, 4vw, 4em) 0 clamp(2em, 4.5vw, 4.5em);
    justify-content: space-between;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .top-books__inner {
    flex-direction: column;
    row-gap: clamp(1.5em, 2vw, 2em);
  }
}
@media (min-width: 768px) {
  .top-books__left {
    width: 48%;
  }
}
@media (max-width: 767px) {
  .top-books__left {
    padding-right: clamp(1.5rem, 5%, 4rem);
  }
}
.top-books__tit {
  display: flex;
  flex-direction: column;
  row-gap: clamp(1em, 2vw, 2em);
  margin-bottom: clamp(1em, 1.5vw, 1.5em);
}
.top-books__tit__add {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: clamp(50px, 20%, 100px);
}
@media (max-width: 767px) {
  .top-books__tit__add {
    padding-left: clamp(1.5rem, 5%, 4rem);
  }
}
.top-books__tit__inner {
  color: #fff;
  background: #4CAE47;
  text-align: center;
  font-weight: 700;
  padding: 0.5em 4%;
  font-size: calc(1.14rem + 16 * (100vw - 280px) / 1160);
}
@media (min-width: 768px) {
  .top-books__tit__inner {
    font-size: calc(1.5rem + 16 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1553px) {
  .top-books__tit__inner {
    font-size: 2.86rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .top-books__tit__inner {
    font-size: 2.67rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .top-books__tit__inner {
    font-size: 2.5rem;
  }
}
@media (max-width: 767px) {
  .top-books__tit__inner {
    padding-left: clamp(1.5rem, 5%, 4rem);
  }
}
@media (min-width: 768px) {
  .top-books__txt {
    line-height: 2;
    padding-left: clamp(2.5em, 16%, 6em);
  }
}
@media (min-width: 1553px) {
  .top-books__txt {
    font-size: 1.29rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .top-books__txt {
    font-size: 1.2rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .top-books__txt {
    font-size: 1.13rem;
  }
}
@media (max-width: 767px) {
  .top-books__txt {
    padding-left: clamp(1.5rem, 5%, 4rem);
  }
}
@media (min-width: 768px) {
  .top-books__right {
    width: 48%;
    padding-right: clamp(1.5em, 5%, 4em);
  }
}
@media (max-width: 767px) {
  .top-books__right {
    padding: 0 clamp(1.5rem, 5%, 4rem);
  }
}
.top-books__list {
  display: grid;
  margin-left: auto;
  margin-right: auto;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 1.5em 3%;
}
@media (min-width: 768px) {
  .top-books__list {
    max-width: 560px;
  }
}
@media (max-width: 767px) {
  .top-books__list {
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
  }
}
.top-books__list li {
  max-width: 260px;
  display: flex;
  flex-direction: column;
  row-gap: 0.5em;
}
.top-books__img {
  width: 100%;
}
.top-books__btn {
  width: 100%;
}
.top-books__btn a {
  display: block;
  text-align: center;
  font-size: calc(0.74rem + 4 * (100vw - 280px) / 1160);
  border-radius: 999px;
  text-decoration: none !important;
  line-height: 1.2;
  padding: 0.75em 4%;
  color: #fff;
  background: #E86607;
  transition: all 0.3s ease 0s;
}
@media (min-width: 768px) {
  .top-books__btn a {
    font-size: calc(0.8rem + 2 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1553px) {
  .top-books__btn a {
    font-size: 1.14rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .top-books__btn a {
    font-size: 1.07rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .top-books__btn a {
    font-size: 1rem;
  }
}
.top-books__btn a:hover {
  background: #ed4d0e;
}

/* top-blog */
.top-blog {
  padding-top: clamp(3em, 6vw, 6em);
  padding-bottom: clamp(5em, 9vw, 9em);
  background: #fff;
}
.top-blog__inner {
  position: relative;
}
.top-blog__tit {
  margin-bottom: clamp(1.5em, 2.5vw, 2.5em);
}
.top-blog__tit b {
  position: relative;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  padding-left: 0.6em;
  padding-right: 0.2em;
  font-size: calc(2rem + 50 * (100vw - 280px) / 1160);
  color: #4CAE47;
}
@media (min-width: 1553px) {
  .top-blog__tit b {
    font-size: 6.57rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .top-blog__tit b {
    font-size: 6.13rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .top-blog__tit b {
    font-size: 5.75rem;
  }
}
.top-blog__tit b::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 0.5em;
  height: 1em;
  background: url(../img/common/icon_pencil.svg) center center/contain no-repeat;
}
.top-blog__tit small {
  font-size: calc(1rem + 10 * (100vw - 280px) / 1160);
}
@media (min-width: 1553px) {
  .top-blog__tit small {
    font-size: 2rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .top-blog__tit small {
    font-size: 1.87rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .top-blog__tit small {
    font-size: 1.75rem;
  }
}
.top-blog__list {
  display: flex;
  flex-direction: column;
  row-gap: clamp(3em, 6vw, 6em);
}
.top-blog__type__tit {
  position: relative;
  font-size: calc(1.25rem + 20 * (100vw - 280px) / 1160);
  font-weight: 700;
  padding-left: 1em;
  margin-bottom: clamp(1em, 1.5vw, 2em);
}
@media (min-width: 1553px) {
  .top-blog__type__tit {
    font-size: 2.86rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .top-blog__type__tit {
    font-size: 2.67rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .top-blog__type__tit {
    font-size: 2.5rem;
  }
}
.top-blog__type__tit::before {
  position: absolute;
  top: 0.6em;
  left: 0;
  content: "";
  width: 0.8em;
  height: 0.15em;
  border-radius: 10px;
  background: #4CAE47;
}
.top-blog__type__tit.is-popular {
  margin-bottom: clamp(3em, 3.5vw, 3.5em);
}
.top-blog__type__list {
  display: grid;
  row-gap: 3em;
}
@media (min-width: 576px) {
  .top-blog__type__list {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    column-gap: 4%;
    row-gap: clamp(2em, 3vw, 3em);
  }
}
@media (min-width: 768px) {
  .top-blog__type__list {
    grid-template-columns: repeat(auto-fit, minmax(280px, 32%));
    column-gap: 2%;
  }
}
@media (min-width: 1176px) {
  .top-blog__type__list {
    grid-template-columns: repeat(auto-fit, minmax(250px, 31.33%));
    column-gap: 3%;
  }
}
@media (min-width: 1553px) {
  .top-blog__type__list {
    grid-template-columns: repeat(auto-fit, minmax(250px, 30%));
    column-gap: 5%;
  }
}
.top-blog__type__list.popular-list {
  row-gap: 4.5em;
}
@media (min-width: 576px) {
  .top-blog__type__list.popular-list {
    row-gap: clamp(3em, 4vw, 4em);
  }
}
@media (min-width: 1176px) {
  .top-blog__btn {
    position: absolute;
    top: 2em;
    right: 0;
    min-width: 240px;
  }
}
@media (max-width: 1175px) {
  .top-blog__btn {
    width: 100%;
    max-width: 430px;
    margin-top: clamp(3em, 4vw, 4em);
    margin-left: auto;
    margin-right: auto;
  }
}
.top-blog__btn a {
  font-size: 1em;
  padding: 0.75em 3%;
}